// src/utils/apiClient.js
import axios from 'axios';

const apiClient = axios.create({
  baseURL: "https://apetech-uruguay.org/api",  // Usar directamente la URL
  timeout: 10000,

});

export default apiClient;
